import { ENTIRELY, MOSTLY, NA, NOT_AT_ALL, PARTIALLY } from "@core/constants";
import usePrompt from "@core/hooks/usePrompt";
import { useSession } from "@core/hooks/useSession";
import {
  addMemberDischargeNote,
  Member,
  MemberDischargeNote,
} from "@core/services/nocd-api";
import { Appointment } from "@core/services/nocd-api/appointments";
import { Select, TextArea, TextField } from "@core/ui";
import getMemberFullName from "@core/utils/formatMemberFullName";
import useDischargeStatus, {
  getDischargeStatusQueryKey,
} from "@features/discharge-requests/hooks/useDischargeStatus";
import CreateTransferRequestDialog from "@features/transfers/components/CreateTransferRequestDialog";
import { checkDischargeActionNeeded } from "@features/transfers/hooks/useIsDischargeActionNeeded";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { format, isValid, parseISO } from "date-fns";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import { useFormik } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";
import { isNil } from "lodash/fp";
import { useState } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";

import DischargeEmailPreviewDialog from "./DischargeEmailPreviewDialog";

const DISCHARGE_OPTIONS = [
  "Discharge - Member achieved outcomes and does not want maintenance support",
  "Discharge - Member no longer interested in service (specify in DC Summary)",
  "Discharge - Member Unresponsive",
  "Discharge - Due to financial concerns",
  "Discharge - Refer Out to a higher level of care (IOP/PHP/RTC)",
  "Discharge - For treatment of other primary diagnosis (specify in DC summary)",
  "Discharge - Refer out to see an in-person therapist at member request",
  "Discharge - Transfer to another NOCD therapist",
  "Discharge - Other (specify in DC Summary)",
];

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

interface DischargeNoteFormProps {
  userId: number;
  member: Member;
  onSuccess: (data: Member) => void;
  setIsDischargeNoteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDischargeNoteDialogOpen: boolean;
  isUpdate?: boolean;
  existingDischargeNote?: MemberDischargeNote;
  scheduledAppointments?: Appointment[];
  currentClinician?: string;
}

const isAppointmentDateValid = (appt: Appointment) => {
  const value = appt.start_time_in_iso8601_utc;
  const valueAsDate = value ? new Date(value) : null;
  return isValid(valueAsDate) ? valueAsDate : undefined;
};
const formatAppointmentInClinicianTime = (
  apptDate: Date,
  clinicianTimezone: string | null
) => {
  const timeZone = clinicianTimezone ?? "America/Chicago";

  if (apptDate) {
    const appointmentInClinicianTime = utcToZonedTime(apptDate, timeZone);

    const date = formatTZ(
      appointmentInClinicianTime,
      "EEE, MMM d, yyy h:mm aaa zzz",
      {
        timeZone,
      }
    );
    return date;
  }
  return undefined;
};

const formatAppointmentListItem = (appt: Appointment) => {
  const apptDate = isAppointmentDateValid(appt);

  const appointmentInClinicianTime = formatAppointmentInClinicianTime(
    apptDate,
    appt?.clinician_timezone
  );

  return (
    <Box alignItems="center" py={1}>
      <Typography variant="subtitle2">
        Appointment ID: {appt.id}- {appt.session_type} -{" "}
        {appointmentInClinicianTime}
      </Typography>
    </Box>
  );
};

// TODO: rewrite this component to use react-hook-form
export default function DischargeNoteForm({
  userId,
  member,
  onSuccess,
  setIsDischargeNoteDialogOpen,
  isDischargeNoteDialogOpen,
  isUpdate,
  existingDischargeNote,
  scheduledAppointments,
  currentClinician,
}: DischargeNoteFormProps): JSX.Element {
  const qc = useQueryClient();

  const { data: dischargeStatus } = useDischargeStatus(userId);

  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const { enableRemoveDischargeAt } = useFlags();
  const prompt = usePrompt<string>();
  const [isPreviewEmailOpen, setIsPreviewEmailOpen] = useState(false);

  const { handleChange, values, handleSubmit, setFieldValue, isSubmitting } =
    useFormik<MemberDischargeNote>({
      initialValues: {
        discharge_at: isUpdate
          ? existingDischargeNote?.discharge_at
          : new Date().toISOString(),
        discharge_reason: isUpdate
          ? existingDischargeNote?.discharge_reason
          : null,
        discharge_barriers_and_notes: isUpdate
          ? existingDischargeNote?.discharge_barriers_and_notes
          : null,
        discharge_care_and_referrals: isUpdate
          ? existingDischargeNote?.discharge_care_and_referrals
          : null,
        adhered_to_treatment_plan: isUpdate
          ? existingDischargeNote?.adhered_to_treatment_plan
          : null,
        updated_by: session.user.email || "",
      },
      onSubmit: async (formValues) => {
        if (!dischargeStatus?.needs_review) {
          const isDischargeActionNeeded = await checkDischargeActionNeeded(
            {
              userId,
              clinicianEmail: session.user.email,
              dischargeReason: formValues.discharge_reason,
            },
            accessToken
          );

          if (isDischargeActionNeeded) {
            try {
              const newTransferRequestResult = await prompt.prompt();

              if (!newTransferRequestResult) {
                toast.error(
                  "Discharge note submission cancelled. Transfer discharge requires an active transfer request."
                );
                return undefined;
              }
            } catch (err) {
              if (err instanceof Error) {
                // eslint-disable-next-line no-console
                console.error("Failed to create transfer request", err.message);
              }
            }
          }
        }

        const promise = addMemberDischargeNote(
          userId,
          formValues,
          accessToken,
          isUpdate,
          dischargeStatus?.needs_review && !isUpdate,
          existingDischargeNote ? existingDischargeNote.id : null
        )
          .then((data) => onSuccess(data))
          .then(() => setIsDischargeNoteDialogOpen(false))
          .then(() => qc.invalidateQueries(getDischargeStatusQueryKey(userId)));

        return toast.promise(promise, {
          loading: "Saving discharge note...",
          success: "Discharge note successfully saved",
          error: (error: Error) => error.message,
        });
      },
    });

  const memberAppointmnetsList = isEmpty(scheduledAppointments ?? [])
    ? null
    : scheduledAppointments.map((appt) => {
        return (
          <ListItem key={appt.id}>
            <ListItemText primary={formatAppointmentListItem(appt)} />
          </ListItem>
        );
      });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={formLabelsTheme}>
        <Dialog
          fullWidth
          maxWidth="md"
          open={isDischargeNoteDialogOpen}
          onClose={() => setIsDischargeNoteDialogOpen(false)}
        >
          <DialogTitle id="form-dialog-title">
            Create Discharge Note
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextArea
                  label="Member Name"
                  value={`${getMemberFullName(
                    member.first_name,
                    member.last_name,
                    member.preferred_name
                  )}`}
                  rows={1}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                {!enableRemoveDischargeAt ? (
                  <TextField
                    disabled
                    label="Note Time"
                    value={
                      isUpdate
                        ? null
                        : format(new Date(), "MMM d, yyy h:mm aaa")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                ) : (
                  <TextField
                    disabled
                    label="Discharge Date Time"
                    value={
                      isUpdate && values.discharge_at
                        ? format(
                            parseISO(values.discharge_at),
                            "MMM d, yyy h:mm aaa"
                          )
                        : format(new Date(), "MMM d, yyy h:mm aaa")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                )}
              </Grid>

              {!enableRemoveDischargeAt ? (
                <Grid item xs={6}>
                  <DateTimePicker
                    label="Discharge Date"
                    format="yyyy/MM/dd hh:mm a"
                    value={parseISO(values.discharge_at)}
                    onChange={(date) => {
                      if (isValid(date)) {
                        return setFieldValue(
                          "discharge_at",
                          date.toISOString()
                        );
                      }

                      return setFieldValue("discharge_at", undefined);
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    disabled={isUpdate}
                  />
                </Grid>
              ) : null}

              {isUpdate ? (
                <Grid item xs={6}>
                  <TextArea
                    label="Updated at"
                    value={format(new Date(), "MMM d, yyy h:mm aaa")}
                    rows={1}
                    disabled
                  />
                </Grid>
              ) : null}

              {memberAppointmnetsList ? (
                <>
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <Typography>
                        Currently scheduled sessions with{" "}
                        {currentClinician ?? ""} listed below will be cancelled
                        after you submit the discharge note.{" "}
                      </Typography>
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: 200,
                        minHeight: 200,
                        height: 200,
                        overflowY: "scroll",
                        border: "3px solid lightgray",
                        borderRadius: 2,
                      }}
                    >
                      {memberAppointmnetsList}
                    </List>
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12}>
                <Autocomplete
                  options={DISCHARGE_OPTIONS}
                  value={values.discharge_reason}
                  onChange={(event, newValue) =>
                    setFieldValue("discharge_reason", newValue)
                  }
                  renderInput={(params) => (
                    <TextField required {...params} label="Discharge reason" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  required
                  label="Adhered to Treatment"
                  name="adhered_to_treatment_plan"
                  value={values.adhered_to_treatment_plan}
                  onChange={handleChange}
                  options={[
                    { value: NOT_AT_ALL },
                    { value: PARTIALLY },
                    { value: MOSTLY },
                    { value: ENTIRELY },
                    { value: NA },
                  ]}
                />
              </Grid>
              {!isEmpty(values?.discharge_reason) &&
              values?.discharge_reason !==
                "Discharge - Transfer to another NOCD therapist" ? (
                <Grid item xs={12}>
                  <Alert severity="info">
                    <Typography>
                      Please note that a discharge email notification will be
                      sent to the Member upon submission of this discharge note.{" "}
                      <Button onClick={() => setIsPreviewEmailOpen(true)}>
                        See preview
                      </Button>
                    </Typography>
                  </Alert>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextArea
                  label="Discharge Summary / Barriers or Challenges to Treatment"
                  name="discharge_barriers_and_notes"
                  value={values.discharge_barriers_and_notes}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextArea
                  label="Anticipated Need for Continued Care and Resource Referrals"
                  name="discharge_care_and_referrals"
                  value={values.discharge_care_and_referrals}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => setIsDischargeNoteDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={() => handleSubmit()}
              disabled={
                isSubmitting ||
                isNil(values.discharge_at) ||
                isNil(values.discharge_reason) ||
                isNil(values.adhered_to_treatment_plan)
              }
            >
              {dischargeStatus?.needs_review && !isUpdate
                ? "Request discharge"
                : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      <CreateTransferRequestDialog
        isOpen={prompt.isOpen}
        onClose={() => prompt.onConfirm(null)}
        userId={userId}
        isForDischargeNote
        onSuccess={prompt.onConfirm}
      />
      {isPreviewEmailOpen ? (
        <DischargeEmailPreviewDialog
          isOpen={isPreviewEmailOpen}
          onClose={() => setIsPreviewEmailOpen(false)}
          dischargeReason={values?.discharge_reason}
          memberName={member?.first_name ?? ""}
        />
      ) : null}
    </StyledEngineProvider>
  );
}
